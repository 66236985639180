import { Routes, Route } from "react-router-dom";
import Dashboard from "../pages/dashboard.component";
import StrengthWeakness from "../pages/StrengthWeakness";
import StudentPerformance from "../pages/StudentPerformance";
import PageNotFound from "../pages/404Page";
import HumanResources from "../pages/humanResources";
import CollegeCareerReadiness from "../pages/CollegeCareerReadiness";
import StudentBehaviour from "../pages/studentbehaviour";
import GraduationRateAnalysis from "../pages/GraduationRateAnalysis";
import Districtgoals from "../pages/districtgoals";
import Enrollment from "../pages/enrollment";
import Studentbehaviorexecutive from "../pages/studentbehaviorexecutive";
import Schoolperformance from "../pages/schoolperformance";
import StateBenchMarking from "../pages/statebenchmarking"
import Login from '../setup/login'
export default function RouteComponent(props) {
    return (
        <Routes>
             <Route exact path="/" element={<Dashboard setUserData={props.setUserData}/>} />
            {/* <Route exact path="/" element={<Login setUserData={props.setUserData}/>} />
            <Route exact path="/dashboard" element={<Dashboard setUserData={props.setUserData}/>} /> */}
            <Route path="*" element={<PageNotFound />} />
            <Route path="/collegecareerreadiness" element={<CollegeCareerReadiness setUserData={props.setUserData}/>} />
            {/* <Route path="/studentbehavior" element={<StudentBehaviour setUserData={props.setUserData}/>} /> */}
            <Route path="/StrengthWeakness" element={<StrengthWeakness setUserData={props.setUserData}/>} />
            <Route path="/StudentPerformance" element={<StudentPerformance setUserData={props.setUserData}/>} />
            <Route path="/humanResources" element={<HumanResources setUserData={props.setUserData}/>} />
            <Route path="/GraduationRateAnalysis" element={<GraduationRateAnalysis setUserData={props.setUserData}/>} />
            <Route path="/districtgoals" element={<Districtgoals setUserData={props.setUserData}/>} />
            <Route path="/enrollment" element={<Enrollment setUserData={props.setUserData}/>} />
            <Route path="/studentbehaviorexecutive" element={<Studentbehaviorexecutive setUserData={props.setUserData}/>} />
            <Route path="/schoolperformance" element={<Schoolperformance setUserData={props.setUserData}/>} />
            <Route path="/statebenchmarking" element={<StateBenchMarking setUserData={props.setUserData}/>} />

        </Routes>
    );
}