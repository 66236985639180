import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getNetSales } from '../services/user'

const initialState = {
  District_Sizeloading: false,
  District_Size: [],

Nearby_Districtloading: true,
Nearby_District: [],

Demographicloading: true,
Demographic: [],

Fundingloading: true,
Funding: [],

Cost_Per_Studentloading: true,
Cost_Per_Student: [],

}

export const fetchDistrict_Size = createAsyncThunk(
  'fetchDistrict_Size',
  async (District_Size, thunkAPI) => {
    District_Size = { ...District_Size, elasticQueryName: "DISTRICT_SIZE" }
    const response = await getNetSales(District_Size);
    return response.data
  }
)


  export const fetchNearby_District = createAsyncThunk(
    'fetchNearby_District',
    async (Nearby_District, thunkAPI) => {
      Nearby_District = { ...Nearby_District, elasticQueryName: "Nearby_District" }
      const response = await getNetSales(Nearby_District);
      return response.data
    }
  )

export const fetchDemographic = createAsyncThunk(
    'fetchDemographic',
    async (Demographic, thunkAPI) => {
        Demographic = { ...Demographic, elasticQueryName: "Demographic" }
        const response = await getNetSales(Demographic);
        return response.data
    }
)

export const fetchFunding = createAsyncThunk(
    'fetchFunding',
    async (Funding, thunkAPI) => {
        Funding = { ...Funding, elasticQueryName: "Funding" }
        const response = await getNetSales(Funding);
        return response.data
    }
)

export const fetchCost_Per_Student = createAsyncThunk(
    'fetchCost_Per_Student',
    async (Cost_Per_Student, thunkAPI) => {
        Cost_Per_Student = { ...Cost_Per_Student, elasticQueryName: "Cost_Per_Student" }
        const response = await getNetSales(Cost_Per_Student);
        return response.data
    }
)
                            
    

export const statebenchmarking = createSlice({
    name: 'statebenchmarking',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder.addCase(fetchDistrict_Size.fulfilled, (state, action) => {
        state.District_Size = action.payload;
        state.District_Sizeloading = false;
      }).addCase(fetchDistrict_Size.pending, (state, action) => {
        state.District_Sizeloading = true;
      })  

      builder.addCase(fetchNearby_District.fulfilled, (state, action) => {
        state.Nearby_District = action.payload;
        state.Nearby_Districtloading = false;
      }).addCase(fetchNearby_District.pending, (state, action) => {
        state.Nearby_Districtloading = true;
      })  

      builder.addCase(fetchDemographic.fulfilled, (state, action) => {
        state.Demographic = action.payload;
        state.Demographicloading = false;
      }).addCase(fetchDemographic.pending, (state, action) => {
        state.Demographicloading = true;
      })  

      builder.addCase(fetchFunding.fulfilled, (state, action) => {
        state.Funding = action.payload;
        state.Fundingloading = false;
      }).addCase(fetchFunding.pending, (state, action) => {
        state.Fundingloading = true;
      })  

      builder.addCase(fetchCost_Per_Student.fulfilled, (state, action) => {
        state.Cost_Per_Student = action.payload;
        state.Cost_Per_Studentloading = false;
      }).addCase(fetchCost_Per_Student.pending, (state, action) => {
        state.Cost_Per_Studentloading = true;
      })  

    }
  })

  export default statebenchmarking.reducer